import type { AppConfig } from '@/services/app-config/service';
import { createLoggingService } from '@/services/logging/service';

export function createDefaultLoggingServiceInstance({ appConfig }: { appConfig: AppConfig }) {
  const isRumEnabled =
    appConfig.environment === 'production' ||
    appConfig.environment === 'staging' ||
    appConfig.environment === 'certification';

  const loggingServiceInstance = createLoggingService({
    config: appConfig.services.logging,
    isRumEnabled,
    rumEventFilter: (event) => {
      if (event?.type === 'error') {
        // If in development, log RUM errors to the console for visibility / troubleshooting.
        if (appConfig.environment === 'development') {
          // eslint-disable-next-line no-console
          console.log(event);
        }

        // note: this handling doesn't belong here, we should be preventing these
        // events from being sent to the logging service at the call site.
        const message = event?.error?.message;
        if (message?.includes('/v1/usernames')) return false;
        if (message?.includes('/v1/promotions/promo_code')) return false;
        if (message?.includes('/v1/promotions/user_referral')) return false;
        return true;
      }
      return true;
    },
    browserLogEventFilter: (event) => {
      // Only send messages to Datadog logs if not in a 'development' environment.
      // And if in development, log the message to the console for visibility.
      // CAUTION: If the logger is configured to forward `console` calls to Datadog,
      // be mindful to _not_ use the same forwarded console levels in this function.
      // For instance, if the logger is configured to forward `console.error` calls to Datadog,
      // do not use `console.error` in this function. Doing so would cause an infinite loop because
      // the logger would capture the `console.error` call from this function and run the `beforeSend`
      // function ad infinitum.
      if (appConfig.environment === 'development') {
        // eslint-disable-next-line no-console
        console.log('not sending log message to datadog because env is development', event);
        return false;
      }

      return true;
    },
  });

  return loggingServiceInstance;
}
