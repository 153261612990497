import { IconTypes } from '@/components/atoms/icon';

// See _variables.scss if this needs to be updated
export const ALTERNATIVE_HOME_ROUTE = '/pick-em/higher-lower/all/home';
export const AMPLITUDE_DEVICE_ID = 'amplitude_device_id';
export const APPEARANCES_UPDATE_TIME = 120000; // 2 min in ms
export const AUTH0_SESSION_REFRESH = 'session_refresh';
export const BADGES_HOST = 'https://assets.underdogfantasy.com/badges';
export const CDN_HOST = 'https://assets.underdogfantasy.com/web';
export const DEFAULT_DEPOSIT_MATCH_AMOUNT = '1000';
export const DEFAULT_ROUTE = '/lobby';
export const DEFAULT_MAX_PAYOUT_MODIFIER = 100;
export const DEFAULT_MAX_INSURED_PAYOUT_MODIFIER = 50;
export const EASY_DEPOSIT_QUERY_PARAM = 'is-easy-deposit';
export const LAST_SELECTED_LINE_ID = 'last_selected_line_id';
export const MIN_STANDARD_PICKS = 2;
export const MIN_INSURED_PICKS = 3;
export const MIN_FORCED_INSURANCE_PICKS = 6;
export const MOBILE_UD_CLIENT_TYPE = 'ud_client_type';
export const MOBILE_UD_CLIENT_VERSION = 'ud_client_version';
export const MOBILE_UD_REF = 'ud_referring_link';
export const NEXT_LOCATION_QUERY_PARAM = 'next';
export const PLAYER_DEEP_LINK_QUERY_PARAM = 'player-id';
export const POWER_UP_SPECIAL_STAT_VALUE = '0.0';
export const TOTAL_LIFETIME_DEPOSITS_QUERY_PARAM = 'total_lifetime_deposits';
export const UD_AUTO_ACCEPT_MODAL_DISMISSED = 'ud_auto_accept_modal_dismissed';
export const UD_BRANCH_DISMISSED = 'ud_branch_dismissed';
export const UD_DEPOSIT_PROMO_DISMISSED = 'ud_deposit_promo_dismissed';
export const UD_DEVICE_THEME = 'ud_device_theme';
export const UD_FAV_EMPTY_STATE_DISMISSED = 'ud_fav_empty_state_dismissed';
export const UD_FEATURE_FLAGS = 'ud_feature_flags';
export const UD_INSTANT_MODAL_DISMISSED = 'ud_instant_modal_dismissed';
export const UD_LOCATION_STATE = 'ud_location_state';
export const UD_LOCATION_TOKEN = 'ud_location_token';
export const UD_OVER_UNDER_CLOSED_ACCORDIONS = 'ud_over_under_closed_accordions';
export const UD_PICK_EM = 'ud_pick_em';
export const UD_PICK_EM_SELECTED_VARIANT = 'ud_pick_em_selected_variant';
export const UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED = 'ud_max_payout_multiplier_modal_dismissed';
export const UD_PROMO_MODAL_DISMISSED = 'ud_promo_modal_dismissed';
export const UD_REF = 'underdog-referring-link';
export const UD_THEME = 'ud_theme';

interface FilterItem {
  id: string;
  title: string;
  description?: string;
  iconName?: IconTypes;
  iconOnClick?: () => void;
}

export const PICKEM_PAGE_MENU_TABS: FilterItem[] = [
  {
    id: 'higher-lower',
    title: 'Higher/Lower',
    description: "Pick higher or lower based on a player's stat projection",
  },
  {
    id: 'rivals',
    title: 'Rivals',
    description: 'Pick which player will accumulate more of the chosen stat',
  },
];

export const PICKEM_PAGE_TIMING_TABS: FilterItem[] = [
  {
    id: 'all',
    title: 'Pre-game & In-game',
  },
  {
    id: 'pre-game',
    title: 'Pre-game',
    description: 'Make picks before the start of the game',
  },
  {
    id: 'in-game',
    title: 'In-game',
    description: 'Make picks based on live, in-game progress. These update often, so act fast!',
    iconName: 'info',
  },
];

export const UD_SOCIALS: { [id: string]: string } = {
  x: 'https://x.com/UnderdogFantasy',
};
