import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider, ThemeWrapper } from '@/components/contexts/theme';

import { loadSvgSpriteSheet } from './assets/svg-sprite-sheet-loader';
import ErrorFallbackWithoutServices from './components/molecules/error-fallback/without-services';
import { createCriticalServicesContainer } from './service-containers/critical/container';
import { CriticalServiceContainerProvider } from './service-containers/critical/context';
import { rootRouter } from './app.routes';

// note: we assert as non-null because we know the element exists in the HTML file
// if it doesn't, we have bigger problems...
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

const renderApp = async () => {
  // We need to wait for the sprite sheet to load, otherwise it's possible
  // that the app will render and no icons will appear.
  // We also need to wait for the critical services container to be created
  // so that other services and containers can be created using critical
  // services if necessary.
  const taskResults = await Promise.allSettled([
    loadSvgSpriteSheet(),
    createCriticalServicesContainer(),
  ]);

  const criticalServicesContainerTaskResult = taskResults[1];

  if (criticalServicesContainerTaskResult.status === 'fulfilled') {
    root.render(
      <CriticalServiceContainerProvider container={criticalServicesContainerTaskResult.value}>
        <ThemeProvider>
          <ThemeWrapper>
            <RouterProvider router={rootRouter} />
          </ThemeWrapper>
        </ThemeProvider>
      </CriticalServiceContainerProvider>
    );
  } else {
    // We can't render the default ErrorFallback here because it depends on SupportLink
    // which depends on Intercom, which is a service that may not be initialized
    // at this point in the app lifecycle.
    // So we render a simpler version of the error fallback that doesn't depend on services.
    root.render(
      <ThemeProvider>
        <ThemeWrapper>
          <ErrorFallbackWithoutServices error={criticalServicesContainerTaskResult.reason} />
        </ThemeWrapper>
      </ThemeProvider>
    );
  }
};

renderApp();
