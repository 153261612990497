import React, { createContext, type ReactNode, useContext, useMemo } from 'react';

import type { CriticalServiceContainer } from './container';

export interface CriticalServiceContainerContextValue {
  container: CriticalServiceContainer;
}

export const CriticalServiceContainerContext =
  createContext<CriticalServiceContainerContextValue | null>(null);

export function CriticalServiceContainerProvider({
  container,
  children,
}: {
  container: CriticalServiceContainer;
  children: ReactNode;
}) {
  const contextValue = useMemo(() => ({ container }), [container]);

  return (
    <CriticalServiceContainerContext.Provider value={contextValue}>
      {children}
    </CriticalServiceContainerContext.Provider>
  );
}

export function useCriticalServiceContainerContext() {
  const context = useContext(CriticalServiceContainerContext);

  if (!context) {
    throw new Error(
      'useCriticalServiceContainerContext must be used within a CriticalServiceContainerContextProvider'
    );
  }

  return context;
}
