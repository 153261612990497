export type OkType<TValue> = { ok: true; value: TValue };

export type ErrorType<TError = undefined> = { ok: false; error: TError };

export type Result<TValue, TError = undefined> =
  | { ok: true; value: TValue }
  | { ok: false; error: TError };

export type ResultOkType<T extends Result<any, any>> = T extends OkType<infer U> ? U : never;

export type ResultErrType<T> = T extends ErrorType<infer U> ? U : never;

export const Ok = <T>(data: T): Result<T, never> => {
  return { ok: true, value: data };
};

export const Err = <E>(error: E): Result<never, E> => {
  return { ok: false, error };
};
