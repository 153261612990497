/* eslint-disable max-classes-per-file */

import { Err, Ok, type Result } from '../result';

export function createServiceDefinitionHelpers<TMeta = unknown>() {
  // The service helper functions below are simply convenience functions for creating
  // service definition objects. We "create" the functions via another function so that
  // they can all share the same metadata type `TMeta`. This way the service container
  // can assume that all services will have the same metadata type if they decide to use it.

  const createInstanceServiceDefinition = <TName extends string, TService>(config: {
    name: TName;
    instance: TService;
    meta?: TMeta;
  }): InstanceServiceDefinition<TName, TService, TMeta> => {
    return {
      type: 'instance',
      name: config.name,
      // We freeze the instance object to try to prevent changes to it after registration.
      instance: Object.freeze(config.instance),
      meta: config.meta,
    };
  };

  const createSyncFactoryServiceDefinition = <TName extends string, TService>(config: {
    name: TName;
    factory: () => TService;
    meta?: TMeta;
  }): SyncFactoryServiceDefinition<TName, TService, TMeta> => {
    return {
      type: 'syncFactory',
      name: config.name,
      factory: config.factory,
      meta: config.meta,
    };
  };

  const createAsyncFactoryServiceDefinition = <
    TName extends string,
    TService,
    TDeps extends ServiceDefinition<any, any, TMeta>[],
  >(config: {
    name: TName;
    factory: (ctx: ServicesMap<TDeps>) => Promise<TService>;
    dependencies?: TDeps;
    meta?: TMeta;
  }): AsyncFactoryServiceDefinition<TName, TService, TMeta, TDeps> => {
    return {
      type: 'asyncFactory',
      name: config.name,
      factory: config.factory,
      dependencies: config.dependencies,
      meta: config.meta,
    };
  };

  return {
    createInstanceServiceDefinition,
    createSyncFactoryServiceDefinition,
    createAsyncFactoryServiceDefinition,
  };
}

interface InstanceServiceDefinition<TName extends string, TService, TMeta> {
  type: 'instance';
  name: TName;
  instance: TService;
  meta?: TMeta;
}

interface SyncFactoryServiceDefinition<TName extends string, TService, TMeta> {
  type: 'syncFactory';
  name: TName;
  meta?: TMeta;
  factory: () => TService;
}

interface AsyncFactoryServiceDefinition<
  TName extends string,
  TService,
  TMeta,
  TDeps extends Array<ServiceDefinition<string, any, TMeta>> = Array<
    ServiceDefinition<string, any, TMeta>
  >,
> {
  type: 'asyncFactory';
  name: TName;
  meta?: TMeta;
  factory: (ctx: ServicesMap<TDeps>) => Promise<TService>;
  dependencies?: TDeps;
}

export type ServiceDefinition<TName extends string, TService, TMeta> =
  | InstanceServiceDefinition<TName, TService, TMeta>
  | SyncFactoryServiceDefinition<TName, TService, TMeta>
  | AsyncFactoryServiceDefinition<TName, TService, TMeta>;

/**
 * This type is used to define a map of service names to their resolved types
 * from a list of service definitions.
 * The intended output is something like
 * {
 *   service1: Service1Type,
 *   service2: Service2Type,
 *   ...
 * }
 */
type ServicesMap<TServiceDefinitions extends ServiceDefinition<any, any, any>[]> = {
  [ServiceDef in TServiceDefinitions[number] as ServiceDef['name']]: ServiceDef extends InstanceServiceDefinition<
    any,
    infer TService,
    any
  >
    ? TService
    : ServiceDef extends SyncFactoryServiceDefinition<any, infer TService, any>
      ? TService
      : ServiceDef extends AsyncFactoryServiceDefinition<any, infer TService, any>
        ? TService
        : never;
};

/**
 * This type is used to define a map of specific, provided service names to their
 * resolved types. This is useful for when you want to get a subset of services
 * from a service container. It differs from `ServicesMap` in that it only includes
 * service names defined by the `TServiceNames` type parameter, whereas `ServicesMap`
 * includes all services defined in the `TServiceDefinitions` type parameter.
 */
export type ServicesMapSubset<
  TServiceDefinitions extends ServiceDefinition<any, any, any>[],
  TServiceNames extends Array<AllServiceNames<TServiceDefinitions>>,
> = {
  [ServiceName in TServiceNames[number]]: ServicesMap<TServiceDefinitions>[ServiceName];
};

/**
 * This type extracts the names of services that are synchronous (instance or sync factory)
 * from a list of service definitions.
 */
type SyncServiceNames<TServiceDefinitions extends ServiceDefinition<any, any, any>[]> = Extract<
  TServiceDefinitions[number],
  InstanceServiceDefinition<any, any, any> | SyncFactoryServiceDefinition<any, any, any>
>['name'];

/**
 * This type extracts the names of services that are asynchronous (async factory)
 * from a list of service definitions.
 */
type AsyncServiceNames<TServiceDefinitions extends ServiceDefinition<any, any, any>[]> = Extract<
  TServiceDefinitions[number],
  AsyncFactoryServiceDefinition<any, any, any, any>
>['name'];

/**
 * This type extracts the names of all services from a list of service definitions.
 */
type AllServiceNames<TServiceDefinitions extends ServiceDefinition<any, any, any>[]> = Extract<
  TServiceDefinitions[number],
  ServiceDefinition<any, any, any>
>['name'];

/**
 * This type extracts the metadata type from a list of service definitions.
 */
type ServiceMeta<TServiceDefinitions extends ServiceDefinition<any, any, any>[]> = Extract<
  TServiceDefinitions[number],
  ServiceDefinition<any, any, any>
>['meta'];

export type ExtractServiceNames<TContainer extends ServiceContainer<any>> =
  TContainer extends ServiceContainer<infer TServiceDefinitions>
    ? AllServiceNames<TServiceDefinitions>
    : never;

export type ExtractServiceMap<TContainer extends ServiceContainer<any>> =
  TContainer extends ServiceContainer<infer TServiceDefinitions>
    ? ServicesMap<TServiceDefinitions>
    : never;

// We use an actual `Map` data structure here as opposed to a record because
// it makes it easier to iterate over the map values while retaining
// type information without needing to do a bunch of casting.
// The type inference for `Object.entries`, `Object.values`, etc... can't seem to
// infer the types of the values for a mapped type.
export type ServiceResolvingErrors<TServiceNames extends Array<string>> = Map<
  TServiceNames[number],
  ServiceResolvingError<TServiceNames[number]>
>;

/**
 * This is a helper type for omitting private properties from a class type.
 * Essentially, TypeScript doesn't omit `private` properties from a class type when
 * extending/implementing the class type. This also means doing `typeof myClassInstance`
 * will include private properties in the type. This is a problem when we want to mock.
 * So we use this helper type to create a new type from a class type that only contains
 * public properties.
 * More info:
 * https://github.com/microsoft/TypeScript/issues/2672
 * https://stackoverflow.com/questions/48953587/typescript-class-implements-class-with-private-functions
 */
type ClassInterface<T> = {
  [P in keyof T]: T[P];
};

/**
 * IMPORTANT: This type is primarily intended for use in unit test mocks and/or storybook mocks.
 * You _can_ use this type elsewhere, but it's recommended to use the `ServiceContainer` type
 * in most code.
 */
export type ServiceContainerInterface = ClassInterface<ServiceContainer<any>>;

export class ServiceContainer<TServiceDefinitions extends ServiceDefinition<any, any, any>[]> {
  private serviceDefinitions: Map<string, ServiceDefinition<string, any, any>> = new Map();

  // note: the cache contains resolved instances of services.
  // This is to prevent creating multiple instances of the same service
  // and to speed up service retrieval after a service has been resolved.
  private serviceCache: Map<string, any> = new Map();

  constructor({ serviceDefinitions }: { serviceDefinitions: TServiceDefinitions }) {
    serviceDefinitions.forEach((def) => {
      this.serviceDefinitions.set(def.name, def);
    });
  }

  getService<TName extends SyncServiceNames<TServiceDefinitions>>(
    name: TName
  ): Result<ServicesMap<TServiceDefinitions>[TName], ServiceResolvingError<TName>> {
    const cachedService = this.serviceCache.get(name);
    if (cachedService) {
      return Ok(cachedService);
    }

    const service = this.serviceDefinitions.get(name);

    if (!service) {
      return Err(
        new ServiceResolvingError({
          message: `Service '${name}' not found in container.`,
          serviceName: name,
          serviceType: 'unknown',
        })
      );
    }

    // We try to get the service from the instance registry first,
    // then the sync factory registry if the instance registry doesn't have it.
    if (service.type === 'instance') {
      this.serviceCache.set(name, service.instance);
      return Ok(service.instance);
    }

    if (service.type === 'syncFactory') {
      let createdInstance: ServicesMap<TServiceDefinitions>[TName];

      try {
        // We freeze the resolved instance to try to prevent changes to it after creation.
        createdInstance = Object.freeze(service.factory());
      } catch (error) {
        // This catch block is intended to catch any errors that occur in the
        // factory function. We don't know what type of errors the factory
        // might throw, if any, but we want to normalize the type of error that
        // we return to the caller, so we wrap the error in a `ServiceResolvingError`.
        return Err(
          new ServiceResolvingError(
            {
              message: `Error occurred while creating service '${name}'.`,
              serviceName: name,
              serviceType: service.type,
            },
            {
              cause: error,
            }
          )
        );
      }

      this.serviceCache.set(name, createdInstance);
      return Ok(createdInstance);
    }

    return Err(
      new ServiceResolvingError({
        message: `Service '${name}' is not a sync factory.`,
        serviceName: name,
        serviceType: service.type,
      })
    );
  }

  async getServiceAsync<TName extends AsyncServiceNames<TServiceDefinitions>>(
    name: TName
  ): Promise<Result<ServicesMap<TServiceDefinitions>[TName], ServiceResolvingError<TName>>> {
    const cachedService = this.serviceCache.get(name);
    if (cachedService) {
      return Ok(cachedService);
    }

    const service = this.serviceDefinitions.get(name);

    if (!service) {
      return Err(
        new ServiceResolvingError({
          message: `Service '${name}' not found in container.`,
          serviceName: name,
          serviceType: 'unknown',
        })
      );
    }

    if (service.type === 'asyncFactory') {
      let ctx: ServicesMap<NonNullable<typeof service.dependencies>> = {};

      if (service.dependencies) {
        const dependencyNames = service.dependencies.map((dep) => dep.name);
        const dependencyResults = await this.getServices(dependencyNames);

        // if there are any issues resolving dependencies, we bail
        if (dependencyResults.errors.size > 0) {
          return Err(
            new ServiceResolvingError({
              message: `Error occurred while resolving dependencies for async service '${name}'.`,
              serviceName: name,
              serviceType: service.type,
              dependencyResolvingErrors: dependencyResults.errors,
            })
          );
        }

        ctx = dependencyResults.services;
      }

      let createdInstance: ServicesMap<TServiceDefinitions>[TName];
      try {
        // We freeze the resolved instance to try to prevent changes to it after creation.
        createdInstance = Object.freeze(await service.factory(ctx));
      } catch (error) {
        // This catch block is intended to catch any errors that occur in the
        // async factory function. We don't know what type of errors the factory
        // might throw, if any, but we want to normalize the type of error that
        // we return to the caller, so we wrap the error in a `ServiceResolvingError`.
        return Err(
          new ServiceResolvingError(
            {
              message: `Error occurred while creating async service '${name}'.`,
              serviceName: name,
              serviceType: service.type,
            },
            {
              cause: error,
            }
          )
        );
      }

      this.serviceCache.set(name, createdInstance);
      return Ok(createdInstance);
    }

    return Err(
      new ServiceResolvingError({
        message: `Service '${name}' is not an async factory.`,
        serviceName: name,
        serviceType: service.type,
      })
    );
  }

  /**
   * This method is always async, even if the requested services are all sync, because
   * it _may_ need to resolve async services.
   * Note: we don't return a `Result` object here because the return value will always
   * be an object with whichever services were able to be resolved as well as an
   * array of any errors that occurred while resolving services.
   * Callers will need to decide if/how to handle any errors that occur as well as how
   * to handle potentially missing services.
   */
  getServices = async <TServiceNames extends Array<AllServiceNames<TServiceDefinitions>>>(
    names: TServiceNames
  ): Promise<{
    services: Partial<ServicesMapSubset<TServiceDefinitions, TServiceNames>>;
    errors: ServiceResolvingErrors<TServiceNames>;
  }> => {
    const services = {} as ServicesMapSubset<TServiceDefinitions, TServiceNames>;

    const servicePromises = names.map((name) => {
      if (this.hasSyncService(name)) {
        return Promise.resolve(this.getService(name));
      }

      if (this.hasAsyncService(name)) {
        return this.getServiceAsync(name);
      }

      return Promise.resolve(
        Err(
          new ServiceResolvingError({
            message: `Service '${name}' not found in container.`,
            serviceName: name,
            serviceType: 'unknown',
          })
        )
      );
    });

    const servicePromisesResults = await Promise.allSettled(servicePromises);

    const errors: ServiceResolvingErrors<TServiceNames> = new Map();

    servicePromisesResults.forEach((serviceResult, index) => {
      const serviceName = names[index];
      // It should be exceedingly rare for a `servicePromise` to reject, because
      // we're returning `Result` objects from the methods called within `servicePromises`.
      // However, we handle it here for completeness.
      if (serviceResult.status === 'rejected') {
        if (serviceResult.reason instanceof ServiceResolvingError) {
          errors.set(serviceName, serviceResult.reason);
        } else {
          errors.set(
            serviceName,
            new ServiceResolvingError(
              {
                message: `Unknown error occurred while resolving service '${serviceName}'.`,
                serviceName,
                serviceType: 'unknown',
              },
              {
                cause: serviceResult.reason,
              }
            )
          );
        }
      }
      // ugh, we have to explicitly check for boolean equality here in order for
      // the `serviceResult.value` type to be narrowed because we don't have TS
      // strict mode enabled.
      else if (serviceResult.value.ok === true) {
        services[serviceName] = serviceResult.value.value;
      } else {
        errors.set(serviceName, serviceResult.value.error);
      }
    });

    return {
      services,
      errors,
    };
  };

  getServiceMeta = <TName extends AllServiceNames<TServiceDefinitions>>(
    name: TName
  ): ServiceMeta<TServiceDefinitions> | undefined => {
    const service = this.serviceDefinitions.get(name);

    return service?.meta;
  };

  hasSyncService = (name: string) => {
    const service = this.serviceDefinitions.get(name);
    return service && (service.type === 'instance' || service.type === 'syncFactory');
  };

  hasAsyncService = (name: string) => {
    const service = this.serviceDefinitions.get(name);
    return service && service.type === 'asyncFactory';
  };
}

export class ServiceResolvingError<TServiceName extends string> extends Error {
  constructor(
    {
      dependencyResolvingErrors,
      message,
      serviceName,
      serviceType,
    }: {
      dependencyResolvingErrors?: ServiceResolvingErrors<string[]>;
      message: string;
      serviceName: TServiceName;
      serviceType: ServiceDefinition<any, any, any>['type'] | 'unknown';
    },
    options?: ErrorOptions
  ) {
    super(message, options);

    // Set the prototype explicitly to ensure `instanceof` works correctly
    Object.setPrototypeOf(this, new.target.prototype);

    this.dependencyResolvingErrors = dependencyResolvingErrors;
    this.serviceName = serviceName;
    this.serviceType = serviceType;
  }

  // We don't have enough type information about the dependencies here to be able
  // to provide a narrower type than `string` for the keys of this object.
  // We would probably need to pass in the entire service definition as a type
  // parameter on `ServiceResolvingError` in order to do so. But that may not
  // always be possible and likely isn't really worth it. These errors are likely
  // not going to be referenced in rendering code and are more for logging.
  public dependencyResolvingErrors?: ServiceResolvingErrors<string[]>;

  public serviceName: TServiceName;

  public serviceType: ServiceDefinition<any, any, any>['type'] | 'unknown';
}
